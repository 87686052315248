<template>
      <div class="">
      <div class="d-flex mb-2">
          <span class="page-header-title">Service Request List</span>
    </div>
  <CCard>
    <CCardBody>
      <div class="d-md-flex justify-content-end mt-3">
        <div>
          <b-input-group class="mb-2">
            <template #append>
              <b-input-group-text @click="OnSearch(searchText)">
                <CIcon name="cil-search" class="cicon cursor" />
              </b-input-group-text>
            </template>
            <b-form-input
              v-model="searchText"
              placeholder="Search"
              v-on:keyup.enter="OnSearch(searchText)"
            >
            </b-form-input>
          </b-input-group>
        </div>
      </div>
      <div class="table-responsive mt-2">
        <CustomTableUser
          class="vertical-align-middle table-responsive account_table"
          :items="serviceRequestList"
          hover
          striped
          border
          :small="'small'"
          fixed
          caption
          :fields="fields"
          :total="total"
          :per-page="serviceFilters.limit"
          :loadTableData="loadTableData"
          :rowSelectHandler="rowClickHandler"
          :onRowDeleteHandler="deleteTableData"
          :onRowEditHandler="editTableData"
          :rowFliterHandler="rowFliterHandler"
          :currentPagination="currentPageOfServiceTable"
        />
      </div>
    </CCardBody>
  </CCard>
  <b-modal
          hide-header
          id="editUser"
          title="Are you sure want to change the role ?"
          ok-title="Yes"
          cancel-title="Cancel"
        >
          <p class="mt-4 mb-2" v-if='engineer_name == ""'>
            Assign Engineer
          </p>
          <p v-else class="mt-4 mb-2">
            Are you sure want to change the Engineer <b>{{engineer_name}}</b>?
          </p>

          <div class="col-lg-6 mt-2 p-0">
            <b-form-select
              v-model="engineer_id"
              :options="engineerModelNameList"
              value-field="id"
              text-field="name"
              class="mr-3"
            ></b-form-select>
            <show-errors
              :control="$v.engineer_id"
              :field="$t('app.template.management.tempalte.name')"
            />
          </div>

          <template v-slot:modal-footer="{}">
            <div class="col-lg-12 col-sm-12 col-12 p-0 row ml-0 mr-0">
              <div class="col-lg-12 col-12 col-sm-12 p-0 text-right">
                <b-button
                  size="md"
                  variant="secondary"
                  class="ml-2 px-2"
                  @click="closeEditModal()"
                >Cancel</b-button>
                <b-button
                  size="md"
                  variant="primary"
                  class="ml-2 px-2"
                  :disabled="editSaveBtn"
                  @click="editRoleType()"
                >Save</b-button>
              </div>
            </div>
          </template>
        </b-modal>
  </div>
</template>

<script>
import { multipleErrorMessages } from "@/shared/utils";
import _ from "lodash";
import { SERVICE_REQUEST_LIST, SERVICE_ASSIGN_ENGINEER,SERVICE_DROPDOWN_ENGINEER } from "@/store/actions.type";
import CustomTableUser from "@/components/Table/TableUser.vue";
import { required } from "vuelidate/lib/validators";
import ShowErrors from "@/components/ShowErrors/ShowErrors.vue";
export default {
  name: "ServiceRequest",
  components: { CustomTableUser,ShowErrors },
  data() {
    return {
      serviceRequestList: [],
      currentPage: 1,
      pageStart: 0,
      perPage: 50,
      total: 0,
      sortField: "created_at",
      sortDirection: "desc",
      serviceFilters:this.$store.state.serviceListPayLoad?JSON.parse((this.$store.state.serviceListPayLoad)): {
        start: 0,
       limit: 50,
        order_by: "desc",
        sort_by: "created_at",
      },
      currentPageOfServiceTable: 1,
      searchText: "",
      engineer_id:"",
      engineer_name:"",
      slot_date:"",
      selectedService_id:"",
      engineerModelNameList: [],
      engineerModelNameListPlaceholder: [{ id: "", name: "Select Engineer" }],
      fields: [
        {
          key: "request_id",
          sortable: true,
          label: "Request No",
          _classes: "request-column-width",
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
          _classes: "created-date-field",
        },
        {
          key: "email",
          label: "Email ID",
          sortable: true,
          _classes: "email-field",
        },
        {
          key: "created_at",
          label: "Created Date",
          sortable: true,
          _classes: "created-date-field",
        },
        {
          key: "controller_type",
          label: "Controller Type",
          _classes: "control-name",
        },
        {
          key: "mtb_type",
          label: "MTB Name",
          sortable: true,
          _classes: "mtb-name",
        },
        {
          key: "service_action",
          label: "Status",
          sortable: true,
          _classes: "mtb-name",
        },
        {
          key: "service_action_edit",
          label: "Action",
          sortable: false,
          _classes: "tbl-width-70 service_action"
        }
      ],
    };
  },
    //validation for the fields
    validations: {
    engineer_id: { required },
  },
  methods: {

    /**
     * @method editTableData
     * @description Opens edit popup
     */
    async editTableData(item) {
      this.selectedService_id = item.id;
      this.slot_date = item.slot_date;
      this.engineer_name = item.assigned_engineer_name?item.assigned_engineer_name:""
      let data = await this.$store.dispatch(SERVICE_DROPDOWN_ENGINEER, {
           slot_date : item.slot_date,
           region_id : item.region_id
        });
        if (_.get(data, "success", true)) {
          this.engineerModelNameList = _.concat(
          ...this.engineerModelNameListPlaceholder,
          ...data
        );
          this.$toast.removeAll();
          this.$bvModal.show("editUser");
        } else {
          this.$toast.removeAll();
          let message = _.get(data, "data.message", "");
          multipleErrorMessages(this.$toast, message);
        }
    },
    /**
     * @method editTableData
     * @description closes edit popup
     */
    closeEditModal() {
      this.engineer_id = "";
       this.selectedService_id = "";
       this.slot_date = "";
       this.engineer_name = ""
      this.$bvModal.hide("editUser");
      // this.mtbModelNameSelected = null;
    },
     /**
     * @method editRoleType
     * @description edit role type of users
     * @author @Jeevan Poojary
     */
     async editRoleType() {
      this.$v.$touch();
      if (
        !this.$v.engineer_id.$invalid
      ) {
        this.editLoading = this.$loading.show();
        // this.editSaveBtn = true;
        let data = await this.$store.dispatch(SERVICE_ASSIGN_ENGINEER, {
          engineer_id : this.engineer_id,
          service_request_id : this.selectedService_id,
          assignment_date:this.slot_date
        });
        // this.editSaveBtn = false;
        if (_.get(data, "success", true)) {
          this.editLoading.hide();
          this.$toast.removeAll();
          this.$v.engineer_id.$reset();
          this.$toast.success(_.get(data, "data.message", ""));
          this.$bvModal.hide("editUser");
          this.fetchServiceRequestList();
          this.engineer_id = "";
          this.engineer_name = "";
          this.selectedService_id = "";
          this.slot_date = "";
        } else {
          this.$toast.removeAll();
          let message = _.get(data, "data.message", "");
          multipleErrorMessages(this.$toast, message);
          this.editLoading.hide();
        }
      }
       this.engineer_id = "";
       this.engineer_name = "";
       this.selectedService_id = "";
       this.slot_date = "";
    },
    /**
     * @method OnSearch
     * @description To handle on search of table data
     * @author @Jeevan Poojary
     */
    async OnSearch(searchText) {
      this.searchText = searchText;

      this.isOnSearchLoading = this.$loading.show();
      let data = await this.$store.dispatch(SERVICE_REQUEST_LIST, {
        filters: {
          start: 0,
         limit: 50,
          order_by: "desc",
          sort_by: "created_at",
        },
        search: this.searchText,
      });
      this.isOnSearchLoading.hide();
      if (_.get(data, "success", true)) {
        this.serviceRequestList = _.get(data, "list", []);
        this.total = _.get(data, "total", 0);
      } else {
        let message = _.get(data, "message", "");
        this.serviceRequestList = [];
        this.total = 0;
        if (message.length > 0) {
          this.$toast.error({
            message: message,
          });
        } else {
          this.$toast.removeAll();
          let messageobj = _.get(data, "message", "");
          multipleErrorMessages(this.$toast, messageobj);
        }
      }
    },
    changeColor() {
      document.documentElement.style.setProperty("--sidebarBgColor", "red");
    },
    rowClickHandler(item) {
      this.$store.commit('set', ['selectedServices',  item.id]);
      this.$store.commit('set', ['serviceListPayLoad',  JSON.stringify(this.serviceFilters)]);
      this.$router.push({
        name: "servicerequestdetails"
      });
    },

    /**
     * @method rowFliterHandler
     * @description Method to load table data with filter
     * @author @Jeevan Poojary
     */
    async rowFliterHandler(e) {
      this.sortDirection = e.asc ? "asc" : "desc";
      this.sortField = e.column;
      this.fetchServiceRequestList();
    },

    /**
     * @method loadTableData
     * @description Method to load table data
     * @author @Jeevan Poojary
     */
    async loadTableData(currentPage, limit) {
      this.currentPage = currentPage;
      this.pageStart =
        this.currentPage == 1 ? 0 : (this.currentPage - 1) * this.perPage;
      this.perPage = limit;
      this.currentPageOfServiceTable = currentPage;
      this.fetchServiceRequestList();
    },
    /**
     * @method fetchServiceRequestList
     * @description Tt will fetch all service data
     * @author @Jeevan Poojary
     */

    async fetchServiceRequestList() {
      this.serviceFilters.start =
        this.currentPage == 1 ? 0 : (this.currentPage - 1) * this.perPage;
      this.serviceFilters.limit = this.perPage;
      this.serviceFilters.sort_by = this.sortField;
      this.serviceFilters.order_by = this.sortDirection;
      this.isServiceLoading = this.$loading.show();
      let data = await this.$store.dispatch(SERVICE_REQUEST_LIST, {
        filters: this.serviceFilters,
        search: this.searchText,
      });
      this.isServiceLoading.hide();
      if (_.get(data, "success", true)) {
        this.serviceRequestList = _.get(data, "list", []);
        this.total = _.get(data, "total", 0);
      } else {
        let message = _.get(data, "message", "");
        this.serviceRequestList = [];
        this.total = 0;
        if (message.length > 0) {
          this.$toast.error({
            message: message,
          });
        } else {
          this.$toast.removeAll();
          let messageobj = _.get(data, "message", "");
          multipleErrorMessages(this.$toast, messageobj);
        }
      }
    },
  },
  async created() {
    let storedPayload = this.$store.state.serviceListPayLoad?JSON.parse(this.$store.state.serviceListPayLoad):null
    this.serviceFilters.start = storedPayload?storedPayload.start:this.serviceFilters.start;
    this.serviceFilters.limit = storedPayload?storedPayload.limit:this.serviceFilters.limit;
    this.perPage = storedPayload?storedPayload.limit:this.perPage;
    this.currentPage = storedPayload?(storedPayload.start / this.perPage)+1:this.currentPage;
    this.currentPageOfServiceTable = storedPayload? storedPayload.start / this.perPage+1:this.currentPageOfServiceTable;
    this.fetchServiceRequestList();
  },
};
</script>
<style>
.request-column-width div {
  width: 150px !important;
}

.service_action div {
  width: 120px !important;
}

.control-name div {
  width: 150px !important;
}

.mtb-name div {
  width: 150px !important;
}

.created-date-field div {
  width: 160px !important;
}

.email-field {
  min-width: 200px !important;
}
</style>
